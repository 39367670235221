import React from "react"
import About from "../about/about"

const MainContent = ({ heading, children }) => {
  return (
    <main className="w-full lg:w-2/3 lg:pl-8 xl:pl-12">
      <About heading={heading}>{children}</About>
    </main>
  )
};

export default MainContent
