import React from "react"
import PropTypes from 'prop-types';

const Sidebar = ({ heading, subheading, children }) => (
  <aside className="w-full lg:w-1/3 lg:border-r border-line lg:px-6 xl:px-12">
    <div className="flex flex-col h-full justify-between">
      <div>
        <h1 className="font-header font-black text-front text-5xl leading-none break-words mb-6">
          {heading}
        </h1>
        <h2 className="font-header font-light text-front text-2xl leading-none mb-4">
          {subheading}
        </h2>
        {children}
      </div>
    </div>
  </aside>
);

Sidebar.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

export default Sidebar
