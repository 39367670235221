import React from "react"
import CustomFonts from "@wkocjan/gatsby-theme-intro/src/components/custom-fonts/custom-fonts"
import Footer from "@wkocjan/gatsby-theme-intro/src/components/footer/footer"
import Header from "@wkocjan/gatsby-theme-intro/src/components/header/header"
import MainContent from "../components/main-content/main-content"
import SEO from "@wkocjan/gatsby-theme-intro/src/components/seo/seo"
import Sidebar from "../components/sidebar/sidebar"
import "@wkocjan/gatsby-theme-intro/src/styles/style.css"
import "../styles/custom.css"

const PageLayout = ({ data, children, title, description, sidebarChildren }) => {
  const { site, profile } = data;
  return (
    <div className="antialiased bg-back leading-normal font-text text-front">
      <SEO title={`${title} | ${site.siteMetadata.title}`} />
      <CustomFonts />

      <Header initials={profile.initials} />

      <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex flex-wrap pt-4 my-8">
        <Sidebar heading={title} subheading={description} children={sidebarChildren}/>
        <MainContent>
          {children}
        </MainContent>
      </div>
      <Footer
        name={profile.name}
        showThemeLogo={site.siteMetadata.showThemeLogo}
      />
    </div>
  )
};

export default PageLayout
