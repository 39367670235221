import React from "react"
import PropTypes from 'prop-types';

const About = ({ heading, children }) => (
  <>
    <h5 className="font-header font-semibold text-front uppercase mb-3">
      {heading}
    </h5>
    <div className="font-text pb-12 leading-normal whitespace-pre-line">
      {children}
    </div>
  </>
)

About.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default About
